import React, { useCallback, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MenuList from "@mui/material/MenuList";
import Modal from "@mui/material/Modal";
import Popper from "@mui/material/Popper";
import Alert from "@mui/material/Alert";
import MenuInfo from "../src/components/MenuInfo/MenuInfo";
import MenuInfoOld from "../src/components/MenuInfoOld/MenuInfoOld";
import Geofence from "../src/components/Geofence/Geofence";

import TrackerInfo from "../src/components/TrackerInfo/TrackerInfo";
import Header from "../src/components/Header/Header";
import MapElement from "../src/components/Map/Map";
import loadWebsocket from "../src/components/websocket/websocket";
import Context from "../src/context";
import selectedTrackContext from "../src/selectedTrackContext";
import selectedTrackViewContext from "../src/selectedTrackViewContext";
import allTrackViewsContext from "../src/allTrackViewsContext";
import goToTrackViewContext from "../src/goToTrackViewContext";
import selectedTrackerTabContext from "../src/selectedTrackerTabContext";
import EmergencyButtons from "../src/components/Emergency/EmergencyButtons";
import axios from "axios";
import LightPanel from "../src/components/LightPanel/LightPanel";
import getConfig from "../src/config/config";
import { updateTrack } from "../src/components/Header/HeaderSlice";
import store from "../src/reduxStore";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import ReportIcon from "@mui/icons-material/Report";
import Head from "next/head";
import { unstable_getServerSession } from "next-auth/next";
import { authOptions } from "./api/auth/[...nextauth]";
import getPermissions from "../lib/auth/permissions";
import { signIn, useSession } from "next-auth/react";

import TrackerWindows from "../src/TrackerWindows";

import { set as setPermissions } from "../src/permissionsSlice";
import { DraggableWindow } from "../src/components/DraggablePopup/DraggablePopup";
import ReplayControlsWindow from "../src/components/ReplayControls/ReplayControls";
import { useAppDispatch, useAppSelector } from "../src/hooks/reduxHooks";
import { update as updateLights } from "../src/redux/slices/lights";
import { getReplayConfig } from "../lib/replayConfig";
import { set as setReplayConfig } from "../src/redux/slices/replay";
import { Socket } from "socket.io-client";
import { ViewState } from "react-map-gl";
import { clear as clearWindows } from "../src/trackerWindowsSlice";
import { clear as clearTrackers } from "../src/components/Map/Marker/trackerSlice";

function getStatus(json) {
  // console.log(json.services);
  let status = "OK";
  Object.keys(json.services).forEach(function (k) {
    if (json.services[k] !== "OK") {
      // console.log(json.services[k]);
      status = "fail";
    }
  });
  return status;
}

export async function getServerSideProps(context) {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  );

  const username = session?.user?.name ?? "";
  const permissions = JSON.parse(
    JSON.stringify(await getPermissions(username))
  );

  const config = getConfig();
  const replay = { ...getReplayConfig(), isFromRemote: true };

  const replays = JSON.parse(
    JSON.stringify(
      await (async () => {
        try {
          const res = await axios.get(config.url.API + "/get-replays");
          if (res.status === 200) {
            return res.data;
          }
        } catch (e) {
          console.error(e);
        }
        return { replays: [] };
      })()
    )
  );

  return {
    props: { config, permissions, defaultReplays: replays, replay },
  };
}

export default function IndexAuth(props) {
  const sessionStatus = useSession({
    required: true,
    onUnauthenticated() {
      signIn("LDAP").then((r) => console.log(r));
      // The user is not authenticated, handle it here.
    },
  }).status;

  store.dispatch(setPermissions(props.permissions));
  // store.dispatch(toggleReplay(props.isReplay))
  //   console.log(props.permissions);

  if (sessionStatus === "loading") {
    return "Checking authorization...";
  }

  return <Index {...props} />;
}

function Index(this: any, props) {
  const { config, permissions } = props;
//   console.log(`Config Values:
//   API URL: ${config.url.API}
//   PLC Address: ${config.url.PLC}
//   Alarms URL: ${config.url.ALARMS}
//   WebSocket URL: ${config.url.WEBSOCKET}
//   Track Status URL: ${config.url.TRACK_STATUS}
//   Reports URL: ${config.url.REPORTS}
//   Bookings URL: ${config.url.BOOKINGS}
//   Booking API URL: ${config.url.BOOKING_API}
//   Dashboard URL: ${config.url.DASHBOARD}
//   Config URL: ${config.url.CONFIG}
//   Booking ID URL: ${config.url.BOOKING_ID}
//   Trackers API URL: ${config.url.TRACKERS_API}
//   InfluxDB URL: ${config.url.INFLUXDB}
//   Host: ${config.host}
//   InfluxDB Token: ${config.influxdb_token}
//   Map: ${config.map}
//   Is Replay: ${config.isReplay}
//   Is QA: ${config.isQA}
//   Version: ${config.version}
//   Node: ${config.node}
//   PLC Disabled: ${config.plcDisabled}
//   Site Prefix: ${config.sitePrefix}
//   Bounds: ${config.swBounds} ${config.neBounds}
//   Track Checks: ${config.trackChecks}
//   Default Track View: ${config.defaultTrackView}`);

//   const firstLoad = useRef(true);

  useEffect(() => {
    if (props.replay.isReplay) {
    //   console.log(props.replay);
      store.dispatch(setReplayConfig(props.replay));
    }
  }, []);

  const replaySettings = useAppSelector((state) => state.replay);

  const [open, setOpen] = React.useState(false);
  const [modalOpen, handleModalOpen] = React.useState(false);
  const [oldmodalOpen, handleoldModalOpen] = React.useState(false);
  const [geofenceOpen, handleGeofence] = React.useState(false);
  const [emergencyOpen, setEmergencyOpen] = React.useState(false);
  const [trackerInfoOpen, handleTrackerInfoOpen] = React.useState(false);
  const [choseOldItem, setChoseOldItem] = React.useState({
    id: undefined,
    menu: undefined,
  });
  const [activeMenu, setActiveMenu] = React.useState({
    id: undefined,
    menu: undefined,
  });
  const [menuItems, setMenuItems] = React.useState({});
  const [leftSelectedItem, makeLeftSelection] = React.useState(
    config.sitePrefix
  );
  const [isLoading, setLoading] = useState(false);
  const [snackBar, showSnackbar] = useState(false);
  const [isShownLightPanel, toggleLightPanel] = useState(false);
  const [originalCopy, setOriginalCopy] = useState<any>(null);
  const [usrPermission, setUsrPermission] = useState("r");
  const [UniqueNameArray, setUniqueNameArray] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(config.sitePrefix);
  const [selectedTrackView, setSelectedTrackView] = useState([0, 0, 0, 0]);
  const [servicesJSON, setServicesJSON] = React.useState({});
  const [status, setStatus] = React.useState("");
  const [goToTrackView, setGoToTrackView] = useState(0);
  const [selectedTrackerTab, setSelectedTrackerTab] = useState("");
  const [trackDirections, setTrackDirections] = useState({});
  const [tracks, setTracks] = useState({});

  const defaultTrackView = config.defaultTrackView.split("|").map(item => parseFloat(item.trim()));
//   console.log(defaultTrackView);
  
  const defaultTrackViewSettings = {
    longitude: defaultTrackView[0],
    latitude: defaultTrackView[1],
    zoom: defaultTrackView[2],
    bearing: defaultTrackView[3],
    pitch: defaultTrackView[4],
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  };

  const [allTrackViews, setAllTrackViews] = useState({
    default: defaultTrackViewSettings,
  });

  // Add this useEffect to log changes
  useEffect(() => {
    // console.log("Selected Track", selectedTrack);
  }, [selectedTrack]);

  const [viewState, setViewState] = useState<ViewState>(defaultTrackViewSettings);

//   Add this useEffect to log changes
  useEffect(() => {
    // console.log("ViewState updated:", viewState);
  }, [viewState]);

  const handleSelectMenu = (menu) => {
    setOpen(false);
    setActiveMenu(menu);
    // const originalCopy = JSON.parse(JSON.stringify(menu))
    // setOriginalCopy(originalCopy)
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showSnackbar(false);
  };

  useEffect(() => {
    if (!replaySettings.isReplay || Object.keys(menuItems).length <= 0) {
      axios
        .get(`${config.url.API}/json`, {
          headers: { accept: "application/json" },
        })
        .then((response) => {
          const data = response.data;
          if (data) {
            const items = data.items || {};
            const usrPermission = data.usrPermission || "r";
            setUsrPermission(usrPermission);
            setMenuItems(items);
            initializeTrackDirections(items);
          } else {
            console.error("No data received from API");
          }
        })
        .catch((error) => {
          console.error("Error fetching menu items:", error);
        });
    }
  }, [replaySettings.isReplay])


  //get site.json file, transform it to look like {prefix: longname}
  useEffect(() => {
    if (!replaySettings.isReplay || Object.keys(menuItems).length <= 0) {
      axios
          .get(`${config.url.API}/tracks`, {
            headers: { accept: "application/json" },
          })
          .then((response) => {
            const data = response.data;
            if (data) {
              let tracks = {};
              tracks[data['site']['prefix']] = data['site']['name'];
              data.tracks.forEach(track =>{
                tracks[track['prefix']] = track['name']
              })
            //   console.log(tracks);
              setTracks(tracks);
            } else {
              console.error("No data received from API");
            }
          })
          .catch((error) => {
            console.error("Error fetching menu items:", error);
          });
    }
  }, [])



  function initializeTrackDirections(json) {
    // Parse the track checks from the environment configuration
    const parsedTrackChecks = config.trackChecks ? config.trackChecks.split(";").map(check => {
      const parts = check.split('|');
      return { trackId: parts[0], divisor: parseInt(parts[1], 10), remainder: parseInt(parts[2], 10) };
    }) : [];

    // Extract track IDs from parsed track checks
    const tracks = parsedTrackChecks.map(check => check.trackId);
    const trackDirs = { ...trackDirections };

    if (tracks.length > 0) {
      tracks.forEach((track) => {
        if (json[track] && json[track]["track-level"]) {
          for (let i = 0; i < json[track]["track-level"].length; i++) {
            if (json[track]["track-level"][i].name === "Direction") {
              trackDirs[track] = json[track]["track-level"][i].value;
            }
          }
        }
      });
      setTrackDirections(trackDirs);
    }
  }

  useEffect(() => {
    axios.get(`${config.url.API}/services`).then((data) => {
      const json = data.data;
      // console.log(json);
      setServicesJSON(json);
      setStatus(getStatus(json));
    });
  }, []);

  useEffect(() => {
    axios.get(`${config.url.API}/track-views`).then((data) => {
      const json = data.data;
    //   console.log(json);
      setAllTrackViews(json);
    });
  }, []);

  useEffect(() => {
    connectMenuEvent();
  }, []);

  function connectMenuEvent() {
    const servicesEvent = new EventSource(
      `${config.url.API}/menu-services-events`
    );
    servicesEvent.onmessage = (e) => {
      const json = JSON.parse(e.data);
      if (json.services) {
        // console.log("Services update received");
        setServicesJSON(json);
        setStatus(getStatus(json));
      }
      if (json.views) {
        setAllTrackViews(json.views);
      }
      if (json.items) {
        if (replaySettings.isReplay && Object.keys(menuItems).length > 0) {
          return;
        }
        setMenuItems(json.items);
        initializeTrackDirections(json.items);
      }
    };
    servicesEvent.onerror = (e) => {
      servicesEvent.close();
      setTimeout(() => {
        // console.log("Reconnecting Menu Event");
        connectMenuEvent();
      }, 1000);
    };
  }

  const modifyArr = (arr, item, kind, event) => {
    return arr.map((i) => {
      if (i.name == item.name) {
        if (kind == "toggle") {
          i.value = !i.value;
        }
        if (kind == "select") {
          i.value = event.target.value;
        }
        if (kind == "number") {
          // console.log(arr, item, kind)
          if (item.name == "MaxNumber") {
            const abMax = arr.filter((o) => o.name == "AbsoluteMaximum")[0];
            if (abMax && Number(event.target.value) > abMax.value) {
              alert("Max Number cannot be greater than Absolute Maximum.");
              return i;
            }
          }

          if (
            Number(event.target.value) > item.max ||
            Number(event.target.value) < item.min
          ) {
            alert(`Please enter number between ${item.min} and ${item.max}`);
            // i.value = i.value
          } else {
            i.value = Number(event.target.value);
          }
        }
      }
      return i;
    });
  };

  const handleChange = (isOld = "new", menu, kind, item, event) => {
    let menuObj: { id: undefined; menu: undefined };
    if (isOld == "old") {
      menuObj = choseOldItem;
    } else {
      menuObj = activeMenu;
    }

    const obj = { ...menuObj };
    obj[menu] = modifyArr(obj[menu], item, kind, event);

    if (isOld == "new") {
      setActiveMenu(obj);
    } else {
      setChoseOldItem(obj);
    }
  };

  const handleSubmit = (isOld = "new") => {
    setLoading(true);
    setTimeout(() => {
      try {
        // update menu json
        // console.log(choseOldItem);
        axios
          .post(
            `${config.url.API}/json`,
            isOld === "new" ? activeMenu : choseOldItem
          )
          .then((post) => {
            setDirectionState(choseOldItem);
            sendPLCPost(choseOldItem).then(() => {
              setOriginalCopy(null);
              showSnackbar(true);
              handleoldModalOpen(false);
            });
          });
      } catch (e) {
        alert("error");
      }
      setLoading(false);
    }, 1000);
  };

  async function sendPLCPost(json) {
    if (config.plcDisabled === "true") {
      return;
    } else {
      const post = await axios.post(`${config.url.PLC}/alarmsTabUpdate`, json);
    }
  }

  function setDirectionState(json) {
    const trackDirs = trackDirections;
    for (let i = 0; i < json["track-level"].length; i++) {
      if (json["track-level"][i].name === "Direction") {
        trackDirs[json.menu] = json["track-level"][i].value;
      }
    }
    setTrackDirections(trackDirs);
  }

  const setOldMenuItem = (item, menu) => {
    handleoldModalOpen(true);
    const originalCopy = JSON.parse(JSON.stringify({ ...item, menu }));
    setOriginalCopy(originalCopy);
    setChoseOldItem({ ...item, menu });
  };

   const handleLeftClick = (item, menuKey) => {
    //    console.log(`Track clicked: ${menuKey}`); // Add this log
       makeLeftSelection(item);
       setSelectedTrack(menuKey);
       store.dispatch(updateTrack(menuKey));
       setGoToTrackView((prev) => prev + 1); // Force update to trigger GoToTrackView effect
   };

  const resetToOriginal = () => {
    if (originalCopy) {
      const obj = { ...menuItems };
      obj[originalCopy.menu] = originalCopy;
      setMenuItems(obj);
      setOriginalCopy(null);
    }
  };

  const [lightPanelInfo, setLightPanelInfo] = useState(null);

  useEffect(() => {
    axios.get(`${config.url.API}/lightpanel`).then((data) => {
      const panelData = JSON.parse(data.data);
      setLightPanelInfo(panelData.items);
      store.dispatch(updateLights(panelData.items));
    });
    // setPermission(panelData.UserPlcAccess)
  }, []);

  const [ws, setWS] = useState<Socket>();

  useEffect(() => {
    const socket = loadWebsocket(config);
    socket.on("trackParameters", (data) => {
      const { items } = JSON.parse(data);
      setMenuItems((old) => {
        // we set the new items using the keys from the old items so we keep the buttons in the same order.
        return Object.fromEntries(
          Object.keys(old).map((key) => [key, items[key]])
        );
      });
      // if(activeMenu)
    });
    setWS(socket);
    return () => {
      console.log("closing websocket");
      socket?.off("connect");
      socket?.off("disconnect");
      socket?.off("close");
      socket?.off("tracker");
      socket?.off("alarm");
      socket?.off("barrierRequest");
      socket?.off("array");
      socket?.off("barriers");
      socket?.off("categories");
      socket?.off("dirmax");
      socket?.off("replay-response");
      socket?.off("trackParameters");
      socket?.off("replay-peer");
      setWS(socket);
    };
  }, []);

  const changeOldItemCallback = useCallback(
    (newItems) => {
    //   console.log("changeOldItemCallback");
      if (replaySettings.isReplay && !!choseOldItem?.menu) {
        // console.log("updating menu");
        setChoseOldItem((old) => ({
          ...newItems[old.menu ?? ""],
          menu: old.menu,
        }));
      }
    },
    [choseOldItem, replaySettings]
  );

  useEffect(() => {
    // console.log("menu items", menuItems);
    changeOldItemCallback(menuItems);
  }, [menuItems]);

  const updateReplayPeer = useCallback(
    (isReplay) => {
      ws?.emit("replay-peer", { ...replaySettings, isReplay: isReplay });
    },
    [replaySettings]
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    handleGeofence(false);
    handleTrackerInfoOpen(false);
    toggleLightPanel(false);
    dispatch(clearWindows());
    dispatch(clearTrackers());
  }, [replaySettings.isReplay]);

  return (
    <Context.Provider value={[UniqueNameArray, setUniqueNameArray]}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <title>RTS vMonitor</title>
      </Head>
      <selectedTrackContext.Provider value={[selectedTrack, setSelectedTrack]}>
        <selectedTrackViewContext.Provider
          value={[selectedTrackView, setSelectedTrackView]}
        >
          <allTrackViewsContext.Provider
            value={[allTrackViews, setAllTrackViews]}
          >
            <goToTrackViewContext.Provider
              value={[goToTrackView, setGoToTrackView]}
            >
              <selectedTrackerTabContext.Provider
                value={[selectedTrackerTab, setSelectedTrackerTab]}
              >
                <Header
                  permissions={props?.permissions}
                  config={config}
                  selectedTrack={selectedTrack}
                  selectedTrackView={selectedTrackView}
                  geofenceOpen={geofenceOpen}
                  handleGeofence={handleGeofence}
                  //   modalOpen={modalOpen}
                  //   handleModalOpen={handleModalOpen}
                  menuItems={menuItems}
                  setOldMenuItem={setOldMenuItem}
                  handleLeftClick={handleLeftClick}
                  leftSelectedItem={leftSelectedItem}
                  toggleLightPanel={(e) => {
                    toggleLightPanel(!isShownLightPanel);
                  }}
                  servicesJSON={servicesJSON}
                  status={status}
                  tracks={tracks}
                />
                <div style={{ zIndex: 99 }}>
                  <Modal
                    open={modalOpen}
                    onClose={() => {
                      handleModalOpen(false);
                    }}
                    disableAutoFocus={true}
                  >
                    <div className="menu-main">
                      <div className="menu-items">
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                        >
                          {Object.keys(menuItems).map((menuKey) => {
                            return (
                              <MenuItem
                                key={menuItems[menuKey].id}
                                className={
                                  activeMenu?.id === menuItems[menuKey].id
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  handleSelectMenu({
                                    ...menuItems[menuKey],
                                    menu: menuKey,
                                  })
                                }
                              >
                                {menuKey} Alarm Control
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </div>
                      <div className="menu-container">
                        <MenuInfo
                          usrPermission={usrPermission}
                          menu={activeMenu}
                          handleChange={handleChange.bind(this, "new")}
                          handleSubmit={handleSubmit.bind(this, "new")}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    open={oldmodalOpen}
                    onClose={() => {
                      handleoldModalOpen(false);
                      resetToOriginal();
                    }}
                    disableAutoFocus={true}
                  >
                    <div className="old-menu-main">
                      <MenuInfoOld
                        usrPermission={usrPermission}
                        resetToOriginal={resetToOriginal}
                        handleModalOpen={handleoldModalOpen}
                        menu={choseOldItem}
                        handleChange={handleChange.bind(this, "old")}
                        handleSubmit={handleSubmit.bind(this, "old")}
                        isLoading={isLoading}
                        config={config}
                      />
                    </div>
                  </Modal>
                  <Snackbar
                    open={snackBar}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose("close", "click")}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Updated!
                    </Alert>
                  </Snackbar>
                </div>
                <div
                  className={"mapDiv"}
                  style={{ height: `${window.innerHeight - 80} px` }}
                >
                  <MapElement
                    config={config}
                    selectedTrack={selectedTrack}
                    viewState={viewState} 
                    setViewState={setViewState}
                    data={UniqueNameArray}
                    trackDirections={trackDirections}
                    geofenceOpen={geofenceOpen}
                  />
                </div>
                <Button
                  style={{
                    position: "fixed",
                    bottom: 10,
                    top: "unset",
                    left: "unset",
                  }}
                  onClick={() => {
                    handleTrackerInfoOpen(true);
                  }}
                >
                  <InfoIcon></InfoIcon>
                </Button>
                {permissions.canAccessPLC ? (
                  <Button
                    style={{
                      position: "fixed",
                      bottom: 10,
                      top: "unset",
                      left: "96%",
                      color: "#FF0000",
                    }}
                    onClick={() => {
                      setEmergencyOpen(!emergencyOpen);
                    }}
                  >
                    <ReportIcon style={{ fontSize: 35 }}></ReportIcon>
                  </Button>
                ) : null}
                <TrackerInfo
                  config={config}
                  handleModalOpen={handleTrackerInfoOpen}
                  trackerInfoOpen={trackerInfoOpen}
                />
                <Popper
                  open={geofenceOpen}
                  style={{
                    position: "fixed",
                    bottom: 60,
                    top: "unset",
                    left: "unset",
                    // maxHeight: '80vh', // Ensure the Popper respects the max height
                    // overflowY: 'auto', // Make the Popper content scrollable if it exceeds the max height
                  }}
                >
                  <div className="geofence">
                    <Geofence
                      config={config}
                      geofenceOpen={geofenceOpen}
                      handleModalOpen={handleGeofence}
                    />
                  </div>
                </Popper>
                <Popper
                  open={emergencyOpen}
                  style={{
                    position: "fixed",
                    bottom: 60,
                    top: "unset",
                    left: "88%",
                  }}
                >
                  <EmergencyButtons
                    config={config}
                    setEmergencyOpen={setEmergencyOpen}
                  />
                </Popper>
                {
                  <DraggableWindow
                    offset={{ x: 20, y: 500 }}
                    open={isShownLightPanel}
                    onClose={() => toggleLightPanel(false)}
                    title={"Light Panel"}
                  >
                    <LightPanel
                      config={config}
                      lightPanelInfo={lightPanelInfo}
                    />
                  </DraggableWindow>
                }
                <ReplayControlsWindow
                  open={replaySettings.controlsOpen}
                  defaultReplays={props.defaultReplays}
                  onLoad={() => {
                    handleGeofence(false);
                    handleTrackerInfoOpen(false);
                    toggleLightPanel(false);
                    updateReplayPeer(true);
                  }}
                  onEnd={() => {
                    updateReplayPeer(false);
                  }}
                />
                <TrackerWindows config={config} />
              </selectedTrackerTabContext.Provider>
            </goToTrackViewContext.Provider>
          </allTrackViewsContext.Provider>
        </selectedTrackViewContext.Provider>
      </selectedTrackContext.Provider>
    </Context.Provider>
  );
}
